import React from 'react';

const Maintenance = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-gray-800 mb-4">
          Бид засвар хийж байна.
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Бяцхан сайжруулалт хийгдэж байгаа тул түр хүлээнэ үү.
        </p>
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-red-500"></div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
