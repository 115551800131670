import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './page/Home';
import Product from './page/ProductPage';
import Contact from './page/Contact';
import Footer from './page/Footer';
import Header from './page/Header';
import GoToTopButton from './component/GoToTopButton';
import ScrollToTop from './component/ScrollToTop';
import Introduction from './page/Introduction';
import About from './page/About';
import News from './page/News'
import Maintenance from './component/Maintanence';

const App = () => {
  return (
    // <BrowserRouter>
    // <ScrollToTop/>
    //   <Header />
    //   <Routes>
    //     <Route path="/" exact element={<Home/>} />
    //     <Route path="/about" element={<About/>} />
    //     <Route path="/news" element={<News />} />
    //     <Route path="/product" element={<Product/>} />
    //     <Route path="/introduction" element={<Introduction/>} />
    //     <Route path="/contact" element={<Contact/>} />
    //   </Routes>
    //   <Footer />
    //   <GoToTopButton /> {/* Include the GoToTopButton component */}
    // </BrowserRouter>
    <Maintenance/>
  );
};

export default App;
