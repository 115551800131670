import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = ({ slides }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="max-h-screen mt-18 lg:relative lg:mt-0 md:relative md:mt-0">
      {slides.map((slide, idx) => (
        <Carousel.Item key={idx} interval={slide.interval}>
          <img
            className="object-cover object-center w-full h-64 sm:h-96 md:h-96 lg:h-full xl:h-full"
            src={slide.image}
            alt={`Slide ${idx + 1}`}
            style={{ objectPosition: "left center" }} // Adjust object position as needed
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Slider;
